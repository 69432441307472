import * as _borsh2 from "borsh";
var _borsh = "default" in _borsh2 ? _borsh2.default : _borsh2;
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BinaryReader = exports.BinaryWriter = exports.BorshError = exports.deserialize = exports.serialize = exports.base_decode = exports.base_encode = void 0;
var borsh_1 = _borsh;
Object.defineProperty(exports, "base_encode", {
  enumerable: true,
  get: function () {
    return borsh_1.baseEncode;
  }
});
Object.defineProperty(exports, "base_decode", {
  enumerable: true,
  get: function () {
    return borsh_1.baseDecode;
  }
});
Object.defineProperty(exports, "serialize", {
  enumerable: true,
  get: function () {
    return borsh_1.serialize;
  }
});
Object.defineProperty(exports, "deserialize", {
  enumerable: true,
  get: function () {
    return borsh_1.deserialize;
  }
});
Object.defineProperty(exports, "BorshError", {
  enumerable: true,
  get: function () {
    return borsh_1.BorshError;
  }
});
Object.defineProperty(exports, "BinaryWriter", {
  enumerable: true,
  get: function () {
    return borsh_1.BinaryWriter;
  }
});
Object.defineProperty(exports, "BinaryReader", {
  enumerable: true,
  get: function () {
    return borsh_1.BinaryReader;
  }
});
export default exports;
export const __esModule = exports.__esModule,
  BinaryReader = exports.BinaryReader,
  BinaryWriter = exports.BinaryWriter,
  BorshError = exports.BorshError,
  deserialize = exports.deserialize,
  serialize = exports.serialize,
  base_decode = exports.base_decode,
  base_encode = exports.base_encode;